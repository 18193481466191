import React from "react";
import {NormalLayout} from "../../../components/layouts/normal_layout";
import {Introduction} from "../../../components/products/skeptron/skeptron_l/introduction";
import {Benefits} from "../../../components/products/skeptron/skeptron_l/benefits";
import {Details} from "../../../components/products/skeptron/skeptron_l/details";
import {Applications} from "../../../components/products/skeptron/skeptron_l/applications";
import {Specifications} from "../../../components/products/skeptron/skeptron_l/specifications";
import {SEO} from "../../../components/seo";

const SkeptronL: React.FC = () => {

  return (
      <NormalLayout type="product">
        <SEO title="Skeptron L | UV Tech by Rendev" description="Skeptron L | UV Tech by Rendev" />
          <Introduction />
          <Benefits />
          <Details />
          <Applications />
          <Specifications />
      </NormalLayout>
  )
}

export default SkeptronL;