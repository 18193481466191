import React from "react";
import {useTranslation} from "react-i18next";
import {Heading} from "../../../common/heading";
import {Section} from "../../../common/section";

export const Specifications = () => {
  const {t} = useTranslation();

  return (
    <Section>
     
    </Section>
  )
}
